import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';
import SunburstHelper from '~/utils/SunburstHelper';

import * as quickviewAction from '~/actions/quickview';

import DropdownList from '~/components/DropdownList';
import SourceClearLoader from '~/components/SourceClearLoader';

import SunburstChartHeader from '~/containers/SunburstChartHeader';
import SunburstChart from '~/containers/SunburstChart';

import BowerPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/Bower';
import NPMPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/NPM';
import MavenPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/Maven';
import GradlePackageInfo from '~/components/ReportComponents/LibraryPackageInfo/Gradle';
import RubyPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/RubyGems';
import PyPIPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/PyPI';
import CocoaPodsPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/CocoaPods';
import PackagistPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/Packagist';

import GlidePackageInfo from '~/components/ReportComponents/LibraryPackageInfo/Glide';
import GoDepPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/GoDep';
import GoVendorPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/GoVendor';
import TrashPackageInfo from '~/components/ReportComponents/LibraryPackageInfo/Trash';

interface ReportExpandedDependencyPathProps {
  component?: object;
  data: object;
  quickviewAction: object;
  quickviewState: object;
  reportScope: object;
  rowId: string;
}
class ReportExpandedDependencyPath extends React.Component<ReportExpandedDependencyPathProps, {}> {
  componentDidMount() {
    // Reset the path whenever someone open and close this quickview
    this.props.quickviewAction.updateStateByRowId(
      this.props.rowId,
      'dependenciesSunburstSelectedPath',
      undefined
    );
  }

  getQuickviewData = () => {
    const { dataByRowId = {} } = this.props.quickviewState;
    return dataByRowId[this.props.rowId] || {};
  };

  onToggleRepoList = () => {
    const { dependenciesRepoListIsOpen = false } = this.getCurrentDependencyState();
    this.props.quickviewAction.updateStateByRowId(
      this.props.rowId,
      'dependenciesRepoListIsOpen',
      !dependenciesRepoListIsOpen
    );
  };

  onRepolistSelect = item => {
    const repo = this.getQuickviewData().repos.find(repo => repo.name === item);
    const repoGraphLink = repo ? repo._links.graph.href : '';

    this.props.quickviewAction.loadQuickview(this.props.rowId, repoGraphLink);
    this.props.quickviewAction.updateStateByRowId(
      this.props.rowId,
      'dependenciesRepoSelectedItem',
      item
    );
    this.props.quickviewAction.updateStateByRowId(
      this.props.rowId,
      'dependenciesSunburstSelectedPath',
      undefined
    );
  };

  getCurrentDependencyState = () => {
    const { stateByRowId = {} } = this.props.quickviewState;
    return stateByRowId[this.props.rowId] || {};
  };

  getCoordAndVersionString = component => {
    return `${component.coord1}${component.coord2 ? ` ${component.coord2}` : ``} ${
      component.version
    }`;
  };

  getFormattedComponent(component, fullGraph) {
    //we are assuming all JAR items are direct dependency
    const jarComponent = fullGraph.find(graph => {
      return (
        graph.coord1 === component.coord1 &&
        graph.coord2 === component.coord2 &&
        graph.version === component.version &&
        graph.matchData[0].dependencyMode === 'DIRECT' &&
        graph.matchData[0].evidenceType === 'JAR'
      );
    });

    return jarComponent
      ? `${jarComponent.coord1} ${jarComponent.matchData[0].filePath} ${jarComponent.version}`
      : this.getCoordAndVersionString(component);
  }

  // Split a string of "angular-animate 1.2.3.0" into coord1 coord2 and version
  getCoordinateAndVersion = str => {
    const pathComponents = str.split(' ');
    const coordinate1 = pathComponents[0];
    const coordinate2 =
      pathComponents.length > 2
        ? pathComponents.slice(1, pathComponents.length - 1).join(' ')
        : undefined;
    const version =
      pathComponents.length > 2 ? pathComponents[pathComponents.length - 1] : pathComponents[1];

    return {
      coordinate1,
      coordinate2,
      version,
    };
  };

  onPathSelected = path => {
    this.props.quickviewAction.updateStateByRowId(
      this.props.rowId,
      'dependenciesSunburstSelectedPath',
      path
    );
  };

  packageInfoForPath(graph, dependenciesSunburstSelectedPath) {
    const { coordinate1 = '', coordinate2 = '', version = '' } = this.getCoordinateAndVersion(
      dependenciesSunburstSelectedPath[0]
    );
    return graph.find(component => {
      // having the below as coordinate 2 from BE is "" sometimes or null sometime
      return (
        component.coord1 === coordinate1 &&
        component.version === version &&
        (component.coord2 === coordinate2 || (!component.coord2 && !coordinate2))
      );
    });
  }

  isGOCoordinateType(directDependencyMatchData) {
    return (
      directDependencyMatchData.dependencyPath &&
      directDependencyMatchData.dependencyPath[0].coordinateType === 'GO'
    );
  }

  render() {
    const quickviewData = this.getQuickviewData();

    const { component = {}, reportScope, quickviewState } = this.props;
    const { isLoading = false } = quickviewState;

    const { repos: currentRepoListScope } = reportScope;
    const isSingleRepoScope = _.isArray(currentRepoListScope) && currentRepoListScope.length === 1;

    const {
      dependenciesRepoListIsOpen = false,
      dependenciesRepoSelectedItem = quickviewData.repos ? quickviewData.repos[0].name : '',
    } = this.getCurrentDependencyState();

    let repoList = quickviewData.repos || [];
    if (isSingleRepoScope) {
      repoList = [repoList.find(repo => repo.id.toString() === currentRepoListScope[0])];
    }
    repoList = repoList.filter(repo => repo !== undefined).map(repo => repo.name);

    const { sunburstPaths: formattedSunburstData = [] } = quickviewData.data.graphs;
    const formattedComponent = quickviewData.data.graphs
      ? this.getFormattedComponent(component, quickviewData.data.graphs.full)
      : {};

    const defaultDependencyPath = SunburstHelper.getDefaultDependencypath(
      formattedSunburstData,
      formattedComponent
    );
    const colorIfPathContains = _.last(defaultDependencyPath);
    const {
      dependenciesSunburstSelectedPath = defaultDependencyPath || [],
    } = this.getCurrentDependencyState();

    const directDependencySelected =
      dependenciesSunburstSelectedPath.length > 0
        ? this.packageInfoForPath(
            quickviewData.data.graphs.full,
            dependenciesSunburstSelectedPath
          ) || {}
        : {};
    const directDependencyMatchData = directDependencySelected.matchData
      ? directDependencySelected.matchData[0] || {}
      : {};
    const directDependencyFilePath = directDependencyMatchData.filePath || '';

    const showColorForHeader = SunburstHelper.isComponentInSelectedPath(
      formattedSunburstData,
      colorIfPathContains,
      dependenciesSunburstSelectedPath
    );

    return (
      <div className="bo-t--1 border-color--muted-light pv">
        <div className="grid grid__item pb- flex flex--align-items--center">
          <div className="grid__item text--bold col-1-3 pl0">
            {repoList.length > 1 && (
              <DropdownList
                onToggle={this.onToggleRepoList}
                isOpen={dependenciesRepoListIsOpen}
                items={repoList}
                value={dependenciesRepoSelectedItem}
                onSelect={this.onRepolistSelect}
              />
            )}
            {repoList.length === 1 && (repoList[0] || '')}
          </div>
        </div>
        {isLoading && (
          <div>
            <SourceClearLoader />
          </div>
        )}
        {!isLoading && (
          <div>
            <div className="grid grid__item">
              <div className="grid__item col-1-2 pl0">
                <SunburstChart
                  onlyColorIfPathContains={colorIfPathContains}
                  data={formattedSunburstData}
                  selectedPath={dependenciesSunburstSelectedPath}
                  onPathSelected={path => this.onPathSelected(path)}
                  width={420}
                  height={450}
                />
              </div>

              <div className="grid__item pl0">
                <div className="min-height--50">
                  <SunburstChartHeader
                    showColorForHeaders={showColorForHeader}
                    headers={dependenciesSunburstSelectedPath}
                    maxWidth={470}
                  />
                </div>

                {directDependencyFilePath.includes('pom.xml') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <MavenPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('build.gradle') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <GradlePackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('bower.json') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <BowerPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('requirements.txt') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <PyPIPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('Gemfile') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <RubyPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {/* Temp hack as filepath filePath is null for package.json */}
                {directDependencyFilePath === '' &&
                  directDependencySelected.coordinateType === 'NPM' &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <NPMPackageInfo
                      filePath={'package.json'}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('package.json') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <NPMPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('npm-shrinkwrap') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <NPMPackageInfo
                      isShrinkWrap={true}
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('Podfile') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <CocoaPodsPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyFilePath.includes('composer') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <PackagistPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {this.isGOCoordinateType(directDependencyMatchData) &&
                  directDependencyFilePath.toUpperCase().includes('GLIDE') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <GlidePackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {this.isGOCoordinateType(directDependencyMatchData) &&
                  directDependencyFilePath.toUpperCase().includes('GODEPS') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <GoDepPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {this.isGOCoordinateType(directDependencyMatchData) &&
                  directDependencyFilePath.toUpperCase().includes('VENDOR.JSON') &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <GoVendorPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {this.isGOCoordinateType(directDependencyMatchData) &&
                  ['VENDOR.CONF', 'TRASH'].includes(directDependencyFilePath.toUpperCase()) &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <TrashPackageInfo
                      filePath={directDependencyFilePath}
                      packageInfo={directDependencySelected}
                    />
                  )}

                {directDependencyMatchData.evidenceType &&
                  directDependencyMatchData.evidenceType === 'JAR' &&
                  !_.isEmpty(dependenciesSunburstSelectedPath) && (
                    <span>
                      {dependenciesSunburstSelectedPath[0]} is a direct JAR dependency found at{' '}
                      {directDependencyFilePath}
                    </span>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    quickviewState: state.quickviewState,
    reportScope: state.reportScope,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    quickviewAction: bindActionCreators(quickviewAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportExpandedDependencyPath);
